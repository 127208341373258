<template>
    <div class="bg-white">
        <EnvBanner />
        <div class="h-screen flex flex-col overflow-auto">
            <Circles />
            <header class="z-50">
                <nav
                    class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
                    aria-label="Global">
                    <div class="flex items-center gap-x-12">
                        <NuxtLink
                            :to="localePath('index')"
                            class="-m-1.5 p-1.5">
                            <span class="sr-only">Juleica</span>
                            <LogoImage />
                        </NuxtLink>
                    </div>
                </nav>
            </header>
            <main class="flex-1">
                <div class="relative z-40">
                    <!-- Your content -->
                    <slot />
                </div>
            </main>
            <Footer />
        </div>
    </div>
</template>

<script setup>

useHead({
    bodyAttrs: {
        class: 'h-full bg-gray-50'
    },
    htmlAttrs: {
        class: 'h-full'
    }
})

// Comment out to deactivate the help pages
//useHelpPage().listenForHelp()
</script>
